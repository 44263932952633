import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "basic-flow-description"
    }}>{`Basic flow description`}</h1>
    <p>{`This section describes the most usual way that AutoBackup can be used. Process description is general, more detailed instructions are referenced as links to other sections.`}</p>
    <p>{`First step you should take is `}<a parentName="p" {...{
        "href": "/configuration/account-onboarding"
      }}>{`onboarding`}</a>{` an account with resources that are supposed to be protected by AutoBackup. At this stage you can also add an account that will be used to store replicas.
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_1.png")} /></p>
    <p>{`After protected account is successfuly onboarded, it gets scanned. Resources are automatically detected and therefore visible in AutoBackup.
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_2.png")} /></p>
    <p>{`While the scanning is in progress, it is a good time to define `}<a parentName="p" {...{
        "href": "configuration/policy"
      }}>{`policies`}</a>{` and `}<a parentName="p" {...{
        "href": "configuration/setup"
      }}>{`setups`}</a>{` that will be assigned to resources.
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_3.png")} />{`
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_4.png")} /></p>
    <p>{`After resource has assigned Policy and Setup (either `}<a parentName="p" {...{
        "href": "usage/operations-on-resources"
      }}>{`manually`}</a>{` or by using AutoBackup `}<a parentName="p" {...{
        "href": "configuration/tags"
      }}>{`tags`}</a>{`) and backup is enabled on a resource, AutoBackup schedules events according to RPO defined in Policy, to ensure that RPO condition is met.
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_5.png")} /></p>
    <p>{`Information about backup process is visible on `}<inlineCode parentName="p">{`Events`}</inlineCode>{` view. On `}<inlineCode parentName="p">{`Event Details`}</inlineCode>{` view you can see the status of event, resources that were part of it and reasons of failure if it ocurred.
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_6.png")} />{`
`}<img alt="Basic flow" src={require("../assets/basic-flow/basic-flow_7.png")} /></p>
    <p>{`Restore data are available for each backed up resource as `}<a parentName="p" {...{
        "href": "usage/snapshot"
      }}>{`snapshot`}</a>{` information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      